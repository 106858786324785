import { DateTime } from 'luxon'

const BarChart = () => import('@/components/charts/BarChart.vue')
const DoughnutChart = () => import('@/components/charts/DoughnutChart.vue')
const DashboardLoader = () => import('@/components/DashboardLoader.vue')
const DashboardEmpty = () => import('@/components/DashboardEmpty.vue')
const DashboardError = () => import('@/components/DashboardError.vue')

export default {
    components: { DashboardLoader, DashboardEmpty, DashboardError, BarChart, DoughnutChart },
    props: { rpid: Number, oid: Number, sid: Number, dates: Array },
    data() {
        return {
            othersSliceColor: 'rgb(150, 150, 150)'
        }
    },
    computed: {
        reportURL() {
            if (!this.rpid) return ''
            let url = `/resource/${this.rpid}`
            if (this.oid && !this.sid) url += `/org/${this.oid}`
            if (this.sid) url += `/space/${this.sid}`
            return url
        }
    },
    methods: {
        dateToLabel(labels) {
            return labels.map(item => {
                const [year, month, date] = item.split('-')
                return date ? [`${month}-${date}`, year] : [month, year]
            })
        },
        findBarChartGroupingResolution(chartData) {
            const startDate = DateTime.fromISO(Object.keys(chartData)[0])
            const endDate = DateTime.fromISO(Object.keys(chartData).reverse()[0])
            const duration = endDate.diff(startDate, ['days', 'months'])
            return duration.as('days') < 32 ? 'daily' : duration.months < 5 ? 'weekly' : 'monthly'
        },
        dataInGBs(data) {
            return data.map(item => (item === null ? NaN : item / (1000 * 1000 * 1000)))
        },
        roundDataItems(data) {
            return data.map(this.roundDataItem)
        },
        roundDataItem(item) {
            const limit = parseFloat('0.' + '0'.repeat(this.precision - 1) + '1')
            return item < limit && item > 0 ? limit : parseFloat(item.toFixed(this.precision))
        }
    },
    watch: {
        reportURL: {
            handler() {
                this.fetchAllCharts()
            },
            immediate: true
        },
        dates(newDatePair) {
            const [startDate, endDate] = newDatePair
            if (!(startDate && endDate)) return
            this.fetchAllCharts()
        }
    }
}
