<template>
    <v-container fluid>
        <v-row>
            <v-col cols="3" ref="sidebar">
                <div v-intersect="intersectionObserver"></div>
                <v-card :class="isIntersecting ? '' : 'types-fixed'" :style="{ width: sidebarWidth }">
                    <v-card-title>Resources</v-card-title>
                    <v-card-text>
                        <dashboard-loader v-if="fetchingChartData.credit_by_sublevels"></dashboard-loader>
                        <template>
                            <template v-if="availableResourceTypes.length > 0">
                                <v-card
                                    flat
                                    color="grey lighten-3 mb-2"
                                    @click="toggle(value)"
                                    v-for="{ label, value, description, color, icon } in availableResourceTypes"
                                    :key="value">
                                    <v-list two-line color="transparent" class="py-0">
                                        <v-list-item>
                                            <v-list-item-avatar>
                                                <v-icon v-if="selectedResourceTypes.indexOf(value) !== -1" :style="`background-color: ${color}`" dark>
                                                    {{ icon }}
                                                </v-icon>
                                                <v-icon v-else class="grey lighten-1" dark>{{ icon }}</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ label }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ description }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </template>
                            <dashboard-empty v-else icon="mdi-format-list-bulleted">No resources</dashboard-empty>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="9">
                <v-row justify="center">
                    <v-col cols="12" sm="6" xl="5">
                        <v-card height="100%">
                            <v-card-title class="pt-2">
                                <v-list-item class="list-item pa-0">
                                    <v-list-item-avatar>
                                        <v-icon large>mdi-developer-board</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6 secondary--text list-title">Credit utilization</v-list-item-title>
                                        <v-list-item-subtitle class="list-subtitle">by resources</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-title>
                            <v-card-text class="flex-grow-1 text-center">
                                <dashboard-loader v-if="fetchingChartData.credit_by_sublevels"></dashboard-loader>
                                <template v-else>
                                    <template v-if="hasData('credit_by_sublevels') && !isError('credit_by_sublevels')">
                                        <div>A total of</div>
                                        <div class="text-h4 secondary--text">{{ totalCreditsByResources }}</div>
                                        <div class="pb-8">AC spent on selected resources</div>
                                        <doughnutChart
                                            :chartData="creditByResource"
                                            aggregateValue="12 NCU"
                                            @legendClick="handleLegendClickResource"
                                            :items="activeLabels" />
                                    </template>
                                    <dashboard-empty v-if="!hasData('credit_by_sublevels')"></dashboard-empty>
                                    <dashboard-error v-if="isError('credit_by_sublevels')"></dashboard-error>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col xl="1" class="d-none d-xl-block"></v-col>
                    <v-col cols="12" sm="6" xl="5">
                        <v-card height="100%">
                            <v-card-title class="pt-2">
                                <v-list-item class="list-item pa-0">
                                    <v-list-item-avatar>
                                        <v-icon large>mdi-file-tree-outline</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6 secondary--text list-title">Credit utilization</v-list-item-title>
                                        <v-list-item-subtitle class="list-subtitle">by sublevels</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-title>
                            <v-card-text class="flex-grow-1 text-center">
                                <dashboard-loader v-if="fetchingChartData.credit_by_sublevels"></dashboard-loader>
                                <template v-else>
                                    <template v-if="hasData('credit_by_sublevels') && !isError('credit_by_sublevels')">
                                        <div>A total of</div>
                                        <div class="text-h4 secondary--text">{{ totalCreditsbySublevels }}</div>
                                        <div class="pb-8">AC spent by selected {{ currentSublevel }}</div>
                                        <doughnutChart :chartData="creditBySublevels" :rpid="rpid" @legendClick="handleLegendClickSublevel" />
                                    </template>
                                    <dashboard-empty v-if="!hasData('credit_by_sublevels')"></dashboard-empty>
                                    <dashboard-error v-if="isError('credit_by_sublevels')"></dashboard-error>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="mt-10">
                    <v-col cols="12">
                        <v-card flat height="100%">
                            <v-card-title class="pt-2">
                                <v-list-item class="list-item pa-0">
                                    <v-list-item-avatar>
                                        <v-icon large>payment</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h6 secondary--text list-title">Credit utilization timeline</v-list-item-title>
                                        <v-list-item-subtitle class="list-subtitle">({{ creditUsageTimelineResolution }})</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-title>
                            <v-card-text>
                                <dashboard-loader v-if="fetchingChartData.credit_utilization"></dashboard-loader>
                                <template v-else>
                                    <bar-chart
                                        :chartData="creditUtilizationTimeLine"
                                        :options="{ stacked: true, yTitle: 'AC' }"
                                        :items="activeLabels"
                                        @legendClick="handleLegendClickResource"
                                        v-if="hasData('credit_utilization') && !isError('credit_utilization')" />
                                    <dashboard-empty v-if="!hasData('credit_utilization')"></dashboard-empty>
                                    <dashboard-error v-if="isError('credit_utilization')"></dashboard-error>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { interpolateColors } from '@/utils'
import * as d3 from 'd3-scale-chromatic'
import dashboard from '@/mixins/dashboard'
import { usageAndResources } from '@/mixins/usageAndResources'
import { orderBy, sum } from 'lodash'
import { DateTime } from 'luxon'

const colorScale = d3.interpolateRainbow
const colorRangeInfo = {
    colorStart: 0,
    colorEnd: 1,
    useEndAsStart: false
}

export default {
    name: 'CreditUtilization',
    mixins: [dashboard, usageAndResources],
    data() {
        return {
            rawChartData: {
                credit_by_sublevels: [],
                credit_utilization: {}
            },
            selectedResourceTypes: [],
            availableResourceTypes: [],
            ignoredSublevels: [],
            colors: {},
            fetchingChartData: {},
            isIntersecting: false,
            sidebarWidth: '',
            intersectionObserver: {
                handler: this.onIntersect,
                options: {
                    // 68px in the CSS too!
                    rootMargin: '-132px 0px 0px 0px',
                    threshold: 1.0
                }
            },
            precision: this.$appConfig?.VUE_APP_DASHBOARD_PRECISION,
            doughnutChartSlicesLimit: 8
        }
    },
    mounted() {
        const styles = window.getComputedStyle(this.$refs.sidebar)
        const width = this.$refs.sidebar.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight)
        this.sidebarWidth = `${width}px`
    },
    computed: {
        creditByResource() {
            if (this.isError('credit_by_sublevels')) return
            const sumByResources = this.sumByResources(this.rawChartData.credit_by_sublevels)
            const slices = this.limitDoughnutChartArcs(sumByResources, this.doughnutChartSlicesLimit)

            const labels = slices.map(slice => slice.name)
            const data = slices.map(slice => slice.valueSum)

            const dataset = {
                labels,
                datasets: [
                    {
                        backgroundColor: slices.map(element => this.resourceTypes.find(resourceType => resourceType.value === element.code).color),
                        data: this.roundDataItems(data)
                    }
                ]
            }
            return dataset
        },
        creditBySublevels() {
            if (this.isError('credit_by_sublevels')) return

            const sumBySublevels = this.sumBySublevels(this.rawChartData.credit_by_sublevels)
            const slices = this.limitDoughnutChartArcs(sumBySublevels, this.doughnutChartSlicesLimit)
            const roundedData = slices.filter(data => {
                return data.valueSum > 0
            })
            const labels = roundedData.map(slice => slice.name)
            const data = roundedData.map(slice => slice.valueSum)
            const colors = interpolateColors(labels.length, colorScale, colorRangeInfo)
            const useOtherColor = labels.includes('Others')
            if (useOtherColor) {
                colors.pop()
                colors.push(this.othersSliceColor)
            }
            const meta = slices.map(slice => {
                return {
                    oid: slice.oid,
                    sid: slice.sid
                }
            })

            return {
                labels,
                datasets: [
                    {
                        backgroundColor: colors,
                        data: this.roundDataItems(data)
                    }
                ],
                meta
            }
        },
        creditUtilizationTimeLine() {
            if (this.isError('credit_utilization')) return

            const datasets = this.groupBarChartDataByDate(this.rawChartData.credit_utilization, this.availableResourceTypes, { targetBarCount: 10 })
            const labels = Object.keys(datasets[0]?.data || {})
            datasets.forEach(set => {
                set.data = this.roundDataItems(Object.values(set.data))
            })

            return {
                labels: this.dateToLabel(labels),
                datasets
            }
        },
        totalCreditsByResources() {
            if (this.isError('credit_by_sublevels')) return
            const sumByResources = this.sumByResources(this.rawChartData.credit_by_sublevels)
            const filteredResources = sumByResources.filter(data => this.selectedResourceTypes.indexOf(data.code) >= 0)
            const total = filteredResources.reduce((total, current) => total + current.valueSum, 0)
            return (Math.round(total * 100) / 100).toFixed(this.precision)
        },
        totalCreditsbySublevels() {
            if (this.isError('credit_by_sublevels')) return
            let data = this.creditBySublevels.datasets[0].data.map(item => parseFloat(item))
            data = data.filter((item, index) => !this.ignoredSublevels.includes(index))
            return sum(data).toFixed(this.precision)
        },
        activeLabels() {
            return this.selectedResourceTypes.reduce((list, current) => {
                list.push(this.resourceTypes.find(type => current === type.value).label)
                return list
            }, [])
        },
        creditUsageTimelineResolution() {
            return this.findBarChartGroupingResolution(this.rawChartData.credit_utilization)
        },
        currentSublevel() {
            if (this.sid) return 'users'
            if (this.oid) return 'spaces'
            return 'organizations'
        }
    },
    methods: {
        onIntersect(entries, observer) {
            this.isIntersecting = entries[0].isIntersecting
        },
        groupBarChartDataByDate(chartData, resourceTypes) {
            const resolution = this.findBarChartGroupingResolution(chartData)

            return resourceTypes.map(resourceType => ({
                label: resourceType.label,
                backgroundColor: resourceType.color,
                data: Object.keys(chartData).reduce((total, day) => {
                    const date = DateTime.fromISO(day)
                    let currentDayLabel = ''

                    switch (resolution) {
                        case 'daily':
                            currentDayLabel = date.toFormat(this.$appConfig?.VUE_APP_DAYFORMAT)
                            break
                        case 'weekly':
                            currentDayLabel = date.startOf('week').toFormat(this.$appConfig?.VUE_APP_WEEKFORMAT)
                            break
                        case 'monthly':
                            currentDayLabel = date.startOf('month').toFormat(this.$appConfig?.VUE_APP_MONTHFORMAT)
                    }
                    const value = parseFloat(chartData[day][resourceType.value])
                    if (!total[currentDayLabel]) total[currentDayLabel] = 0
                    total[currentDayLabel] = Math.round((total[currentDayLabel] + value) * 100) / 100
                    return total
                }, {})
            }))
        },
        sumByResources(chartData) {
            const d = []
            for (const sublevel of chartData.map(item => item.value)) {
                for (const type in sublevel) {
                    const resourceType = this.resourceTypes.find(resourceType => resourceType.value === type)
                    const code = resourceType.value
                    const name = resourceType.label

                    if (sublevel[type] === 0) continue

                    const e = d.find(e => e.code === code)
                    if (e) {
                        e.valueSum += sublevel[type]
                    } else {
                        d.push({
                            code,
                            name,
                            valueSum: sublevel[type]
                        })
                    }
                }
            }
            return d
        },
        sumBySublevels(chartData) {
            const summedBySublevel = chartData.map(data => {
                const valuesSum = Object.keys(data.value).reduce((total, current) => {
                    if (this.selectedResourceTypes.indexOf(current) < 0) return total
                    return total + data.value[current]
                }, 0)
                data.valueSum = valuesSum
                return data
            })
            return summedBySublevel
        },
        limitDoughnutChartArcs(data, limit = 8) {
            const orderedData = orderBy(data, 'valueSum', ['desc'])
            if (data.length <= limit) return orderedData

            const slices = orderedData.splice(0, limit - 1)
            const othersSliceValue = orderedData.reduce((total, current) => total + current.valueSum, 0)
            slices.push({ name: 'Others', valueSum: othersSliceValue })

            return slices
        },
        getUsedResourceTypes() {
            // We are only getting resources types from credit_by_sublevels
            const resourceTypes = this.rawChartData.credit_by_sublevels
                .map(item => item.value)
                .reduce((set, current) => {
                    for (const key in current) {
                        if (current[key] > 0) set.add(key)
                    }
                    return set
                }, new Set())

            this.availableResourceTypes = [...resourceTypes].map(value => this.resourceTypes.find(resourceType => resourceType.value === value))
            this.selectedResourceTypes = this.availableResourceTypes.map(({ value }) => value)
        },
        async fetchChartData(chart) {
            try {
                this.fetchingChartData[chart] = true
                const [startDate, endDate] = this.dates
                const payload = {
                    start_date: startDate,
                    end_date: endDate
                }
                const { data } = await this.$axios.post(`${this.reportURL}/${chart}`, payload)
                this.fetchingChartData[chart] = false
                return data
            } catch (error) {
                console.log('error:', error)
                this.fetchingChartData[chart] = false
                return error
            }
        },
        fetchAllCharts() {
            const promises = []
            const rawChartDataKeys = Object.keys(this.rawChartData)
            rawChartDataKeys.forEach(chart => {
                this.rawChartData[chart] = {}
                promises.push(this.fetchChartData(chart))
            })
            Promise.all(promises).then(values => {
                values.forEach((value, i) => {
                    this.rawChartData[rawChartDataKeys[i]] = value
                })
                this.getUsedResourceTypes()
            })
        },
        toggle(value) {
            const indexOfResource = this.selectedResourceTypes.indexOf(value)
            indexOfResource !== -1 ? this.selectedResourceTypes.splice(indexOfResource, 1) : this.selectedResourceTypes.push(value)
        },
        handleLegendClickResource(item) {
            const { value } = this.resourceTypes.find(type => type.label === item.text)
            const indexOfResource = this.selectedResourceTypes.indexOf(value)
            indexOfResource !== -1 ? this.selectedResourceTypes.splice(indexOfResource, 1) : this.selectedResourceTypes.push(value)
        },
        handleLegendClickSublevel(item) {
            if (item.hidden) {
                this.ignoredSublevels = this.ignoredSublevels.filter(ignoredItem => ignoredItem !== item.index)
            } else {
                this.ignoredSublevels.push(item.index)
            }
        },
        hasData(chart) {
            const data = this.rawChartData[chart]
            if (!data) return
            if (Array.isArray(data)) {
                return data.length > 0
            } else {
                return Object.keys(data).length > 0
            }
        },
        isError(chart) {
            return this.rawChartData[chart] instanceof Error
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/dashboard.scss';

.types-fixed {
    position: fixed;
    margin: 0;
    z-index: 1;
    top: 132px;
}
</style>
